@import "~bootstrap/scss/bootstrap";

// @import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body {
    background: #000;
    font-size: 16px;
    color: #00ff00;
    font-family: "VT323", monospace;
}

.form-control,
.form-control:focus {
    background: #000;
    color: #00ff00;
    font-size: 2rem;
    border: 1px solid #00ff00ab;
    box-shadow: 0 0 10px #00ff00ab;
    border-radius: 0;
}

p.aaaaaaaaahiminspectiiiiiing {
    color: transparent;
    text-shadow: 0 0 10px rgb(0, 255, 0);

    &::-moz-selection,
    &::selection {
        color: #00ff00;
        text-shadow: none;
    }
}
